<!--
 * @Author: xwn 3299704183@qq.com
 * @Date: 2022-11-20 19:16:16
 * @LastEditors: xwn 3299704183@qq.com
 * @LastEditTime: 2022-12-02 14:06:29
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\abnormalFeedback.vue
 * @Description: 异常提醒及反馈
-->
<template>
  <div class="center-main1">
    <el-scrollbar style="height: calc(100vh - 132px)">
      <div class="head-title">
        <div class="head-titleL">
          <div>
            <span class="mandatory">*</span>
            <span>巡课年级 {{ ruleForm.gradeName }}</span>
          </div>
          <div>
            <span class="mandatory">*</span>
            <span>巡课班级 {{ ruleForm.className }}</span>
          </div>
        </div>
        <div class="head-titleL">
          <div>
            <span class="mandatory">*</span>
            <span>上课日期 {{ ruleForm.classDate }}</span>
          </div>
          <div>
            <span class="mandatory">*</span>
            <span>课程 {{ ruleForm.subjectName }}</span>
          </div>
        </div>
        <div class="head-titleL">
          <div>
            <span class="mandatory">*</span>
            <span>上课时间段 {{ ruleForm.classPeriod }}</span>
          </div>
          <div>
            <span class="mandatory">*</span>
            <span>督导教师 {{ ruleForm.teacherName }}</span>
          </div>
        </div>
      </div>
      <div class="labels">
        <div v-for="(item, index) of ruleForm.labelDate" :key="index">
          <template v-if="item.labelType == '2'">
            {{ item.behaviorLabelName }}
            <div class="form-item">
              <el-tooltip
                effect="dark"
                :content="item.behaviorLabelName"
                placement="top-start"
              >
                <span class="label">{{ item.behaviorLabelName }}</span>
              </el-tooltip>
              <el-checkbox-group v-model="item.dataName" class="sel">
                <el-checkbox
                  v-for="it in labelValue"
                  :label="it.labelvalue"
                  :key="it.value"
                  :value="it.value"
                  disabled
                >
                  {{ it.labelvalue }}
                </el-checkbox>
              </el-checkbox-group>
              <div class="abnormal-wrapper">
                <el-checkbox
                  class="abnormal"
                  true-label="1"
                  false-label="0"
                  disabled
                  v-model="item.isAbnormal"
                >
                  <span class="label">异常</span>
                </el-checkbox>
              </div>
            </div>
          </template>
          <template v-if="item.labelType == '1'">
            <div class="form-item">
              <el-tooltip
                effect="dark"
                :content="item.behaviorLabelName"
                placement="top-start"
              >
                <span class="label">{{ item.behaviorLabelName }}</span>
              </el-tooltip>
              <el-radio-group v-model="item.dataName" class="sel">
                <el-radio
                  :key="item.dataId"
                  :label="item.dataName"
                  :value="item.dataId"
                  disabled
                  >{{ item.dataName }}</el-radio
                >
              </el-radio-group>
              <div class="abnormal-wrapper">
                <el-checkbox
                  class="abnormal"
                  true-label="1"
                  false-label="0"
                  disabled
                  v-model="item.isAbnormal"
                >
                  <span class="label">异常</span>
                </el-checkbox>
              </div>
            </div>
          </template>
          <template>
            <div class="tip-title" v-if="isSubmit == '1'">
              <span class="mandatory">*</span>
              <span style="margin-right: 10px; width: 120px">异常结果反馈</span>
              <textarea
                cols="60"
                rows="10"
                readonly="readonly"
                v-model="item.abnormalResultFeedback"
              ></textarea>
            </div>
            <div class="tip-title" v-else>
              <span class="mandatory">*</span>
              <span style="margin-right: 10px; width: 120px">异常结果反馈</span>
              <textarea
                cols="60"
                rows="10"
                v-model="item.abnormalResultFeedback"
              ></textarea>
            </div>
          </template>
        </div>
      </div>
      <div>
        <xk-button
          class="submit"
          @click="submitForm"
          type="primary"
          v-if="isSubmit != '1'"
          >提交</xk-button
        >
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import { classScheduleModel } from "@/models/ClassSchedule.js";
import { debounce } from "common-local";
import ElementUI from "element-ui";
export default {
  data() {
    return {
      isSubmit: "", //是否已提交
      ruleForm: {
        gradeName: "",
        className: "",
        classDate: "",
        classPeriod: "",
        teacherName: "",
        subjectName: "",
        labelDate: [
          {
            isAbnormal: "",
            behaviorLabelId: "",
            behaviorLabelName: "",
            dataId: "",
            dataName: "",
            labelType: "",
            abnormalResultFeedback: "",
            dataList: [],
          },
        ],
      },
      detailList: {
        observedRecordId: "",
        list: [],
        // abnormalResultFeedback:"",
        // behaviorLabelId:""
      },
      observedRecordId: "",
      labelValue: [],
    };
  },
  created() {
    this.observedRecordId = this.detailList.observedRecordId = this.$route.query.observedRecordId
    this.getInfo()
  },
  methods: {
    /**
     * @Description: 获取异常反馈详情
     * @DoWhat:获取详情
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-28 17:44:14
     */
    getInfo() {
      const classSchedule = new classScheduleModel();
      classSchedule
        .getAbnormalDetail({
          observedRecordId: this.observedRecordId,
        })
        .then((res) => {
          console.log(res, "获取异常详情");
          if (res.data.code == "200") {
            this.ruleForm = res.data.data;
            this.isSubmit = res.data.data.isSubmit;
            console.log(this.isSubmit, "this.isSubmit");
            this.ruleForm.labelDate.forEach((item) => {
              if (item.labelType == 2) {
                const strArr = item.dataName.split(",");
                item.dataName = strArr;
                console.log(strArr, "多选");
                strArr.forEach((it) => {
                  if (it) {
                    this.labelValue.push({
                      labelvalue: it,
                    });
                  }
                });
              }
              console.log(this.labelValue, "是对象吗？");
            });
          } else if (res.data.msg) {
            this.$message.error(res.data.msg);
          }
        });
    },
    /**
     * @Description: 提交异常反馈结果
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: xwn
     * @Date: 2022-11-20 21:57:57
     */
    submitForm() {
      this.detailList.list = [];
      this.ruleForm.labelDate.find((item) => {
        this.detailList.list.push({
          abnormalResultFeedback: item.abnormalResultFeedback,
          behaviorLabelId: item.behaviorLabelId,
        });
      });
      let a = 0;
      const arr = [];
      let auth = false;
      this.ruleForm.labelDate.map((i) => arr.push(i.abnormalResultFeedback));
      console.log(arr, "???");
      arr.forEach((it) => {
        if (it == "" || typeof it === "undefined") {
          a++;
        }
        if (a != 0 || typeof it === "undefined") {
          return (auth = false);
          a = 0;
        } else {
          return (auth = true);
          a = 0;
        }
      });
      if (!auth) {
        this.$message.warning("请填写异常结果反馈");
      } else {
        const classSchedule = new classScheduleModel();
        classSchedule.getAbnormalSave(this.detailList).then((res) => {
          if (res.data.code == "200") {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.$router.push({
                path: "/",
              });
            }, 3000);
          } else if (res.data.msg) {
            this.$message.error(res.data.msg);
          }
        });
      }
      console.log(auth, "111???");
    },
  },
};
</script>
<style scoped lang="scss">
.center-main1 {
  overflow: hidden;
  height: calc(100vh - 132px);
  margin: 10px;
  border-radius: 8px;
  background-color: #fff;
}
.head-title {
  width: 550px;
  color: #808387;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: auto;
  padding-top: 30px;
  .head-titleL {
    height: 40px;
    display: flex;
    justify-content: space-between;
  }
}
.labels {
  width: 600px;
  margin: auto;
  .form-item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    .label {
      width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //   border-right: 1px solid #c8cacc;
      color: #808387;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      box-sizing: border-box;
      padding-right: 10px;
    }
    .sel {
      flex: 1;
      padding-left: 10px;
    }
  }
  .abnormal-wrapper {
    display: flex;
    box-sizing: border-box;
    // padding: 0 20px 20px 20px;
    .abnormal {
      width: 100px;
      .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808387;
      }
    }
  }
  .tip-title {
    display: flex;
    color: #808387;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}
.mandatory {
  color: #f56c84;
}
.submit {
  // width: 100%;
  margin: 50px 500px;
}
</style>
