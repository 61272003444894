<template>
    <!--钉钉消息点击跳转页面，实现单点登录-->
    <div></div>
</template>

<script>
import { getCode } from "@/libs/dingtalk";
import { setToken } from "@/libs/auth";
import * as dd from "dingtalk-jsapi";
import { param2Obj } from "@/libs/utils";
import { mapState } from 'vuex';

export default {
    created() {
        this.init()
    },
    computed: {
        ...mapState({
            projectMark: state => state.projectMark,
        })
    },
    methods: {
        init() {
            let urlParam = this.$route.query
            if (dd.env.platform !== 'notInDingTalk' && urlParam.observedRecordId) {
                const projectMark = this.projectMark || ''
                getCode(code => {
                    this._fet('/auth/sc/dingLoginHszx', {
                        code: code,
                        projectMark: projectMark,
                        mode: 'pc'
                    }).then((res) => {
                        if (res.data.code === '200') {
                            setToken(res.data.data.token);
                            this.$router.push({
                                path: '/abnormal/feedback',
                                query: {
                                    observedRecordId: urlParam.observedRecordId,
                                }
                            })
                        } else {
                            this.$router.push({
                                name: '/login'
                            });
                        }
                    })
                }, projectMark)
            } else {
                this.$router.push({
                    name: '/login'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
