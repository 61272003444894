<template>
  <!-- 个人档案 -->
  <UserProfileScrollWrapper />
</template>

<script>
import UserProfileScrollWrapper from "@/components/scrollWrapper/UserProfile";

export default {
  name: "UserProfile",
  components: {
    UserProfileScrollWrapper
  }
}
</script>
