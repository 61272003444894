<template>
  <!--工作台 > 驾驶舱-->
  <div>
    <div>
      <!-- 驾驶舱 -->
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    disable () {
      // console.log('禁用')
    }
  }

}
</script>

