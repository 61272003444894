<template>
  <!-- 修改密码 -->
  <user-change-password-scroll-wrapper />
</template>

<script>
import UserChangePasswordScrollWrapper from "@/components/scrollWrapper/UserChangePassword";
export default {
  name: "UserInfo",
  components: {
    UserChangePasswordScrollWrapper
  }
}
</script>

