<template>
    <section class="xk-layout">
        <slot></slot>
    </section>
</template>
<script>
    export default {
        name: "XkLayout",
    }
</script>
<style lang="scss" scoped>
    .xk-layout {
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: var(--xk-page-bg);
        flex: auto;
    }
</style>
