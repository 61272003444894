<template>
  <!-- 个人信息 -->
  <user-info-scroll-wrapper />
</template>

<script>
import UserInfoScrollWrapper from "@/components/scrollWrapper/UserInfo";
export default {
  name: "UserInfo",
  components: {
    UserInfoScrollWrapper
  }
}
</script>

