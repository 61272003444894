<template>
    <class-manage-cloud-marking-scroll-wrapper></class-manage-cloud-marking-scroll-wrapper>
</template>
<script>
import ClassManageCloudMarkingScrollWrapper from '@/components/scrollWrapper/ClassManageCloudMarking.vue';
export default {
    name: "ClassManageCloudMarking",
    components: {
        ClassManageCloudMarkingScrollWrapper,
    }
}
</script>